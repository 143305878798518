<template>
  <div class="recharge-order-result">
    <loading v-if="loading"/>
    <template v-else-if="rechargeOrder.paid">
      <b-card>
        <div class="text-center">
          <div class="mb-2">
            <fa icon="check-circle" class="text-success" size="5x"/>
          </div>
          <h2 class="title">充值成功</h2>
          <h5 v-if="afterRecharge">正在返回下单页面继续付款 {{countDown}}</h5>
        </div>
        <div class="order-info">
          <table class="table border-less">
            <tbody>
            <tr>
              <td>充值金额</td>
              <td>{{$rmb(rechargeOrder.price)}}</td>
            </tr>
            <tr>
              <td>实付</td>
              <td>{{$rmb(rechargeOrder.paidMoney)}}</td>
            </tr>
            <tr>
              <td>创建时间</td>
              <td>
                <datetime :value="rechargeOrder.createTime"/>
              </td>
            </tr>
            <tr>
              <td>支付时间</td>
              <td>
                <datetime :value="rechargeOrder.paidTime"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </b-card>

      <div class="btn-area">
        <b-btn block :to="afterRecharge" variant="primary" v-if="afterRecharge">继续付款</b-btn>
        <b-btn block to="/my/wallet">查看我的钱包</b-btn>
      </div>
    </template>

    <template v-else>
      <b-card>
        <div class="text-center">
          <div class="mb-2">
            <fa icon="times-circle" class="text-danger" size="5x"/>
          </div>
          <div class="title mb-3">
            <h2>充值失败</h2>
            <div>如已扣款，请勿再次支付</div>
          </div>
        </div>
        <table class="table border-less">
          <tbody>
          <tr>
            <td>订单号</td>
            <td>{{rechargeOrder.id}}</td>
          </tr>
          <tr>
            <td>充值金额</td>
            <td>{{$rmb(rechargeOrder.price)}}</td>
          </tr>
          <tr>
            <td>待付</td>
            <td>{{$rmb(rechargeOrder.paidMoney)}}</td>
          </tr>
          <tr>
            <td>创建时间</td>
            <td>
              <datetime :value="rechargeOrder.createTime"/>
            </td>
          </tr>
          </tbody>
        </table>
      </b-card>

      <div class="btn-area">
        <qiyu class="btn-block"></qiyu>
        <b-btn block to="/my/recharge">返回充值页面</b-btn>
      </div>
    </template>
  </div>
</template>

<script>
import routeData from '@/mixins/route-data'
import Qiyu from '@/components/Qiyu'

export default {
  name: 'rechargeOrderSuccess',
  title: '充值结果',
  components: {Qiyu},
  mixins: [routeData('rechargeOrder')],
  data() {
    return {
      countDown: 5
    }
  },
  computed: {
    afterRecharge() {
      const bookId = this.$ss.getItem('afterRecharge')
      if (!bookId) {
        return ''
      }
      return '/books/' + bookId + '/buy'
    }
  },
  methods: {
    onLoad() {
      if (this.rechargeOrder.relateOrderNo) {
        return this.$router.replace('/pay/' + this.rechargeOrder.relateOrderNo)
      }
      if (this.afterRecharge) {
        this.startCountDown()
      }
    },
    startCountDown() {
      const bookId = this.$ss.getItem('afterRecharge')
      this.interval = setInterval(() => {
        this.countDown--
        if (this.countDown < 1) {
          this.$ss.setItem('checked.' + bookId, 1)
          this.$router.push(this.afterRecharge)
          clearInterval(this.interval)
        }
      }, 1000)
    }
  }
}
</script>

<style scoped lang="scss">
  .table {
    margin-top: 1em;
    margin-bottom: 0;
  }

  .my .recharge-order-result.content {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 480px;

    .title {
      text-align: center;
      margin-bottom: .75em;
    }
  }
</style>
