<template>
  <div class="recharge">
    <page-title>余额充值</page-title>
    <loading v-if="loading"/>
    <template v-else>
      <b-card title="充值消费规则">
        <ol>
          <li v-for="rule in rules" :key="rule" v-html="rule"></li>
        </ol>
      </b-card>

      <b-card title="选择充值类型">
        <div class="mb-3" v-show="adTextRaw">
          <span id="extra"></span>
        </div>

        <template v-if="validRechargeList.length">
          <div :class="{active: selected === item}" :data-tag-left="item.tag"
               @click="selected = item"
               class="choice warning"
               :key="item.id"
               v-for="item in validRechargeList">
            <div class="content">
              充￥{{item.price | round(2)}}
              <span class="discount" v-if="item.discount">{{item.discount}}</span>
            </div>

            <div class="desc" v-html="item.description"></div>

            <span class="check" v-if="selected === item">
              <fa icon="check"/>
            </span>
          </div>
        </template>

        <div :class="{active: selected === custom}"
             @click="selected = custom"
             class="choice"
             v-else>
          <div class="content p-0">
            <b-input class="text-center" size="lg" id="customPrice"
                     max="10000" min="0"
                     placeholder="点击输入充值金额"
                     type="number" v-model.number="custom.price"/>
          </div>
          <label class="desc">
            自定义金额充值不享受优惠
          </label>
          <span class="check" v-if="selected === custom">
            <fa icon="check"/>
          </span>
        </div>

        <b-row align-v="center" class="mt-4 mb-3" v-if="selected !== custom">
          <b-col>
            <b>充值数量</b>
            <div class="small text-muted">充值越多优惠越多</div>
          </b-col>
          <b-col cols="auto">
            <stepper v-model="count" min="1" max="99"/>
          </b-col>
        </b-row>

        <label><b>总计到账</b></label>
        <div class="details">
          <b-row align-v="center">
            <b-col>余额增值</b-col>
            <b-col cols="auto">{{$rmb(origin)}}</b-col>
          </b-row>

          <template v-if="coupons.length">
            <b-row align-v="center" v-for="item in coupons" :key="item.couponNo">
              <b-col>
                加赠{{item.discount}}优惠券
                <div class="text-muted small">
                  <span v-if="item.expires">有效期{{item.expires}}天</span>
                  <span class="limit" v-if="item.products.length">
                    {{item.products | showProducts}}
                  </span>
                  <span class="limit" v-if="item.binding.length">
                    {{item.binding | showBindings}}
                  </span>
                  <span class="limit" v-if="item.publishTypes.length">
                    {{item.publishTypes | showPublishTypes}}
                  </span>
                  <span class="no-limit"
                        v-if="!item.products.length && !item.binding.length && !item.publishTypes.length">
                    无使用限制
                  </span>
                </div>
              </b-col>
              <b-col cols="auto">&times; {{item.count * count}}</b-col>
            </b-row>
          </template>
        </div>
      </b-card>

      <b-card class="pay-type" title="选择支付方式">
        <cell title="微信支付" v-model="payType" val="wxpay">
          <img slot="head" class="icon" src="@/assets/images/icons/wxpay.svg">
        </cell>

        <cell title="支付宝支付" v-model="payType" val="alipay" v-if="!isMiniProgram">
          <img slot="head" class="icon" src="@/assets/images/icons/alipay.svg">
        </cell>

        <cell title="请好友代付" v-model="payType" val="wxpay_web_friend">
          <img class="icon" slot="head"
               src="https://img.xinshu.me/upload/00bf4542454f4be184488ba809bb12eb">
        </cell>
      </b-card>

      <b-btn variant="primary" @click="createOrder" block :disabled="!origin || submitting">
        <span v-if="submitting">正在提交</span>
        <span v-else>提交订单</span>
      </b-btn>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import { get, orderBy, round } from 'lodash'

import routeData from '@/mixins/route-data'

import Stepper from '@/components/Stepper'
import Countdown from '@/components/Countdown'

export default {
  name: 'recharge',
  title: '余额充值',
  mixins: [routeData('giftCards')],
  components: {Stepper},
  data() {
    return {
      selected: {},
      custom: {
        id: 0,
        price: ''
      },
      count: 1,
      payType: 'wxpay',
      alipayUrl: '',
      submitting: false,
      rules: [],
      adTextRaw: ''
    }
  },
  created() {
    this.fetchRechargeRule()
  },
  computed: {
    coupons() {
      if (!this.selected || !this.selected.couponActivityIds) {
        return []
      }
      const {couponActivities, couponActivityIds} = this.selected
      return couponActivityIds.map(i => {
        const coupon = couponActivities[i.activityNo]
        return {
          count: i.count,
          price: coupon.price,
          expires: coupon.couponExpireGap,
          products: coupon.products || [],
          publishTypes: coupon.publishTypes || [],
          binding: coupon.binding || [],
          discount: coupon.price >= 1 ? coupon.price + '元' : round(coupon.price * 10, 2) + '折'
        }
      })
    },
    validRechargeList() {
      if (this.isAgent && !this.isDev) {
        return []
      }
      return this.giftCards
    },
    origin() {
      if (!this.selected) {
        return 0
      }
      return this.selected.price * (this.selected.id ? this.count : 1)
    },
    total() {
      return this.origin + this.totalCouponValue
    }
  },
  methods: {
    onLoad() {
      const value = Number(this.$route.query.value)
      if (value) {
        this.custom.price = value
        this.giftCards = []
      }
      this.giftCards = orderBy(this.giftCards, 'price')
      this.giftCards = orderBy(this.giftCards, i => !i.description.includes('$HOT$'))
      this.giftCards.forEach(i => {
        i.description = i.description.replace('$HOT$', '')
      })
      this.selected = this.validRechargeList[0]
      if (!this.selected) {
        this.selected = this.custom
      }
      this.initAdText()
    },
    async createOrder() {
      if (!Number(this.selected.price) || this.selected.price <= 0) {
        return this.$alert.error('请输入正确的充值金额哦')
      }

      if (this.origin > 10000) {
        return this.$alert.error('仅支持 10000 元以下的充值金额哦')
      }

      try {
        this.submitting = true

        const orderData = {
          cardId: this.selected.id,
          payType: this.payType,
          price: round(this.selected.price, 2)
        }

        if (orderData.cardId !== 0) {
          orderData.count = this.count
        }

        const order = await this.$req.post('/api/gift_card/order/create', orderData)
        this.$router.push('/pay/' + order.orderNo)
      } catch (err) {
        this.submitting = false
      }
    },
    initAdText() {
      return this.$ajax.fetchPromotion()
        .then(data => get(data, 'giftcards.adText'))
        .then(text => {
          this.adTextRaw = text
          if (text) {
            const matches = text.match(/{(.+?) (.+)}/)
            if (matches && matches.length >= 2) {
              try {
                const component = matches[1]
                const value = matches[2]
                const templateString = text.replace(
                  /{(.+?) (.+)}/,
                  `<${component} value="${value}"></${component}>`
                )
                // {countdown 2018/1/1 00:00}
                // 遇到上述格式的字符串则将 countdown 作为组件名
                // 后面的作为组件的 value
                // 调用 Vue.compile 将字符串解析为 DOM，然后插入到 #adText
                const res = Vue.compile('<div>' + templateString + '</div>')
                new Vue({
                  components: {Countdown},
                  render: res.render,
                  staticRenderFns: res.staticRenderFns
                }).$mount('#extra')
              } catch (err) {
                this.$el.querySelector('#extra').innerHTML = text
              }
            } else {
              // 若无花括号特殊格式则作为普通 HTML 字符串处理
              this.$el.querySelector('#extra').innerHTML = text
            }
          }
        })
    },
    fetchRechargeRule() {
      return this.$req.get('/buckets/weixinshu:rechargeRules')
        .then(data => {
          this.rules = data
        })
    }
  }
}
</script>

<style scoped lang="scss">
.recharge {
  padding-left: .5em;
  padding-right: .5em;

  .icon-inline {
    height: 1.5em;
    vertical-align: middle;
  }

  ol {
    margin: 0;
    padding-left: 1.25em;
  }

  .list {
    text-align: center;
    padding: 1em;
  }

  .choice {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    border-radius: $border-radius;
    border: 1px solid $input-border-color;
    color: #222;
    margin-bottom: 1em;
    cursor: pointer;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    transition: .3s;

    .desc {
      width: 100%;
      text-align: center;
      padding: 5px 10px;
      font-size: 12px;
      border-top: 1px dashed $input-border-color;
      margin-bottom: 0;
      transition: border-top-color .3s;
    }

    &.active {
      border-color: $primary;
      color: $primary;

      .desc {
        border-top-color: $primary;
      }

      .info {
        border-left-color: $primary;
      }

      input {
        background-color: #fff !important;
      }
    }

    .check {
      position: absolute;
      top: 0;
      right: 0;
      color: $white;
      font-size: 12px;
      line-height: 1;
      padding: 1px;
      padding-left: 12px;
      padding-bottom: 12px;
      background-color: $primary;
      background: linear-gradient(45deg, transparent 18px, $primary 0);
      transform-origin: top right;
      transform: scale(.75);
    }

    .content {
      padding: .5em;
      text-align: center;
      height: 100%;
      flex-basis: 100%;
      width: auto;

      input {
        border: 0;
        border-radius: 0;
        outline: none;
        box-shadow: none;
        background-color: #f9f9f9;
        transition: .3s;
      }
    }

    .info {
      margin: 0;
      height: 100%;
      white-space: nowrap;
      padding: .25em 1em;
      text-align: center;
      font-size: 12px;
      min-width: 8em;
      border-left: 1px dashed #D0D0D0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .discount {
        font-size: 1.5em;
        line-height: 1.2;
      }
    }

    .discount {
      font-size: 1.5em;
    }
  }

  .icon {
    height: 1.5em;
  }

  .btn-pay {
    border: 0;
    @include media-breakpoint-down(sm) {
      padding-top: 1em;
      padding-bottom: 1em;
    }

    &.alipay {
      color: #fff;
      background: lighten(#00AAEE, 5%);
    }

    &.wxpay {
      color: #fff;
      background: lighten(#44B549, 5%);
    }
  }
}
</style>
