<template>
  <div class="countdown">{{text}}</div>
</template>

<script>
export default {
  name: 'countdown',
  props: ['value', 'rawText'],
  data() {
    return {
      text: ''
    }
  },
  computed: {
    endDate() {
      if (this.value) {
        return this.value
      }
      if (this.rawText) {
        return this.rawText.match(/{countdown (.+?)}/)[1]
      }
      return ''
    }
  },
  watch: {
    endDate(val) {
      if (val) {
        this.startCountDown(val)
      }
    }
  },
  mounted() {
    if (this.endDate) {
      this.startCountDown(this.endDate)
    }
  },
  beforeDestroy() {
    this.stopCountDown()
  },
  methods: {
    startCountDown(val) {
      clearInterval(this.interval)
      const moment = this.$day
      const eventTime = moment(val).unix()
      const currentTime = moment().unix()
      const diffTime = eventTime - currentTime
      let duration = moment.duration(diffTime * 1000, 'milliseconds')
      const interval = 1000

      const _countdown = () => {
        duration = moment.duration(duration.asMilliseconds() - interval, 'milliseconds')
        const d = moment.duration(duration).days()
        const h = moment.duration(duration).hours()
        const m = moment.duration(duration).minutes()
        const s = moment.duration(duration).seconds()
        const list = []
        this.text = ''

        if (d > 0) {
          list.push(d + ' 天')
        }
        if (h > 0) {
          list.push(h + ' 小时')
        }
        if (m > 0) {
          list.push(m + ' 分')
        }
        if (s > 0) {
          list.push(s + ' 秒')
        }

        this.text = list.join(' ')
        // 倒计时结束自动刷新页面
        if (this.text === '') {
          location.reload()
        }
      }
      if (diffTime > 0) {
        _countdown()
        this.interval = setInterval(_countdown, interval)
      }
    },
    stopCountDown() {
      this.text = ''
      clearInterval(this.interval)
    }
  }
}
</script>

<style scoped lang="scss">
  .countdown {
    display: inline-block;
  }
</style>
