<template>
  <div class="balance-detail">
    <page-title>余额明细</page-title>
    <b-card>
      <template v-if="loading">
        <ske block/>
        <ske block/>
        <ske block/>
        <ske block/>
        <ske block/>
      </template>

      <template v-if="balanceDetail.length">
        <header class="mb-3">
          <fa icon="info-circle"/>
          以下为所有余额相关的记录
        </header>

        <div class="text-muted text-center" v-if="isSmallScreen">左右滑动表格查看更多信息</div>
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th>时间</th>
              <th>相关订单</th>
              <th>金额</th>
              <th>来源</th>
            </tr>
            </thead>
            <tr v-for="item in balanceDetail" :title="item.id" :key="item.id"
                :class="{'table-primary': active === item.id}">
              <td>
                <datetime :value="item.createTime"/>
              </td>
              <td>
                <div v-if="item.orderId > 0">{{item.orderId}}</div>
                <span v-else>/</span>
              </td>
              <td>
                <span v-if="item.amount > 0" class="text-success">{{$rmb(item.amount)}}</span>
                <span v-if="item.amount < 0" class="text-danger">{{$rmb(item.amount)}}</span>
                <div class="small" v-if="item.paidMoney">实付{{$rmb(item.paidMoney)}}</div>
              </td>
              <td>{{item.category | categoryType}}</td>
            </tr>
          </table>
        </div>

        <b-btn block @click="loadMore" v-if="!ended" :disabled="loadingMore">
          <fa icon="spinner" spin v-if="loadingMore"/>
          加载更多
        </b-btn>
      </template>

      <empty icon="coins" v-else>暂无余额明细</empty>
    </b-card>
  </div>
</template>

<script>
import routeData from '@/mixins/route-data'

export default {
  name: 'balance',
  title: '余额明细',
  mixins: [routeData('balanceDetail')],
  data() {
    return {
      active: Number(this.$route.query.id),
      page: 1,
      loadingMore: false,
      ended: false,
      showOrderId: false
    }
  },
  filters: {
    categoryType(val) {
      const types = ['其他', '充值', '消费', '退款', '赠送', '返现', '下级代理', '粉丝订单']
      return types[val]
    }
  },
  methods: {
    onLoad() {
      this.ended = this.balanceDetail.length < 15
    },
    loadMore() {
      this.loadingMore = true
      return this.fetchBalanceDetail({page: ++this.page}).then(data => {
        this.loadingMore = false
        this.balanceDetail = this.balanceDetail.concat(data)
        if (data.length < 15) {
          this.ended = true
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.balance-detail {
  .table {
    margin-bottom: 0;
    white-space: nowrap;
  }
}
</style>
